import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";

export const Wrapper = styled(Grid)``;

export const Title = styled(Grid)`
  font: ${theme.font.title_26_semibold};
  color: ${theme.color.petrol};
  line-height: ${theme.lineHeight.textBlock};
`;

export const ColoredWord = styled.span`
  color: ${theme.color.orange};
  @media (max-width: 959px) {
    color: ${theme.color.white};
  }
`;

export const MailLink = styled.a`
  font: ${theme.font.title_26_semibold};
  color: ${theme.color.orange};
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 0.09em;
  @media (max-width: 959px) {
    color: ${theme.color.white};
    font: ${theme.font.text_16};
  }
`;

export const ContactText = styled.span`
  font: ${theme.font.title_26_semibold};
  color: ${theme.color.petrol};
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16};
  }
`;
