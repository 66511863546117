import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { theme } from "../../../../theme";

export const Wrapper = styled(Grid)`
  background-color: ${theme.color.white};
  height: ${theme.headerHeight};
  font: ${theme.font.navigation};
  display: flex;
  flex-direction: row;
  box-shadow: 1px 1px 1px 0.5px ${theme.color.shadow};
  border-radius: 1px;
  position: fixed;
  width: 100%;
  z-index: 9999;
`;

export const Links = styled(Grid)`
  padding: 2em 0 2em 5em;
  height: 40%;
`;

export const LinkHeader = styled(NavLink)`
  color: ${theme.color.black};
  font-weight: ${theme.fontWeight.semiBold};
  text-decoration: none;
  padding-right: 2em;

  &:hover {
    color: ${theme.color.petrol};
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 0.09em;
  };

  &.active {
    color: ${theme.color.petrol};
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 0.09em;
  };

  height: 50%;
`;

export const BrandingHeader = styled(Grid)``;

export const Image = styled.img`
  padding: 0.7em 5em 0.7em 0;
  height: 4em;
`;

export const IconLink = styled.a`
  color: ${theme.color.black};
  &:hover {
    color: ${theme.color.petrol};
  };
`;
