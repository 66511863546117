import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export const Wrapper = styled(Grid)`
  padding: 40px 0 0;
  text-align: center;
  background-color: ${theme.color.lightpetrol};
  .petrol {
    color: ${theme.color.petrol};
  }

  @media (max-width: 839px) {
    padding: 40px 20px 20px;
  }
`;
export const InfoWrapper = styled(Grid)`
  padding: 30px 100px;
  background-color: ${theme.color.white};

  .petrol {
    color: ${theme.color.petrol};
  }
`;
export const InnerWrapper = styled(Grid)`
  margin-bottom: 15px !important;
  margin-right: 15px !important;
  width: 250px !important;
  text-align: left;
  background-color: ${theme.color.white};

  @media (max-width: ${theme.breakPointMobile3}) {
    border: none;
    width: 100% !important;
  }
`;

export const Title = styled(Grid)`
  text-align: center;
  font: ${theme.font.title_50};
  color: ${theme.color.white};
  margin-bottom: 50px !important;

  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.title_26_semibold};
    margin-bottom: 20px !important;
  }
`;

export const InfoTitle = styled(Grid)`
  text-align: left;
  font: ${theme.font.title_30_semibold};
  color: ${theme.color.petrol};
  margin-bottom: 30px !important;

  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.title_26_semibold};
    margin-bottom: 25px !important;
  }
`;

export const SubTitle = styled(InfoTitle)`
  font: ${theme.font.title_26};
  margin-bottom: 15px !important;

  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.titleMobile_24};
    margin-bottom: 5px !important;
  }
`;

export const Paragraph = styled.div`
  margin-bottom: 26px;
  &.no-margin {
    margin-bottom: 0;
  }
`;

export const Info = styled(Grid)`
  font: ${theme.font.text_20};
  color: ${theme.color.black};
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16};
  }
`;

export const ButtonContainer = styled(Grid)`
  padding-bottom: 60px;
  .btn:focus {
    background-color: ${theme.color.petrol};
    color: ${theme.color.white};
    box-shadow: 0px 4px 4px rgba(34, 131, 152, 0.26);
  }
`;

export const Button = styled.button`
  margin: 15px;
  width: 180px;
  font: ${theme.font.text_20};
  background-color: #ffffff;
  color: ${theme.color.petrol};
  padding: 20px 0px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
`;

export const DesktopView = styled(Grid)`
  display: block !important;
  @media screen and (max-width: 839px) {
    display: none !important;
  }
`;

export const MobileView = styled(Grid)`
  display: none !important;

  @media screen and (max-width: 839px) {
    display: block !important;
    width: 100%;
  }
`;

export const Content = styled(Grid)``;

export const AccordionContentContainer = styled(Grid)`
  width: 90%;
`;

export const StyledLink = styled.a`
  color: ${theme.color.black};
`;

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",

      "& .MuiAccordionSummary-root": {
        color: theme.color.petrol,
        borderRadius: 10,
        borderBottom: "none",
      },
      "& .MuiAccordion-rounded": {
        borderRadius: 10,
        marginBottom: 10,
      },
      "& .MuiAccordion-root.Mui-expanded": {
        margin: " 12px 0",
        display: "block",
        flexGrow: 0,
        borderRadius: 10,
      },
      "& .MuiAccordionSummary-content": {
        margin: " 12px 0",
        display: "block",
        flexGrow: 0,
      },
      "& .MuiAccordionSummary-content.Mui-expanded": {},
      "& .MuiAccordionSummary-root.Mui-expanded": {
        color: theme.color.white,
        backgroundColor: theme.color.petrol,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    title: {
      font: theme.font.text_20,
      marginBottom: 10,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      "@media (max-width: 720px)": {
        font: theme.font.text_16,
      },
    },
    detail: {
      marginTop: 15,
      //font: theme.fontDesktop.SombraRegular_26,
      "@media (max-width:768px)": {
        // eslint-disable-line no-useless-computed-key
      },
    },
  })
);
