import {
  Wrapper,
  Title,
  ColoredWord,
  Text,
  TextCOC,
  InsurancesContainer,
  InsuranceBox,
  ContentContainer,
  ImageContainer,
  ContactInfo,
  StyledImage,
  DataPrivacyLink,
  LinkText,
} from "./style";
import insurances from "./constants";

export const PartnerInsurances = () => {
  const renderInsuranceBoxes = insurances.map((insurance) => (
    <InsuranceBox>
      <ContentContainer>
        <ImageContainer>
          <StyledImage
            width={insurance.width}
            height={insurance.height}
            src={insurance.image}
            alt={insurance.alt}
          />
        </ImageContainer>
        {insurance.unfallNumber ? (
          <ContactInfo>
            Unfallversicherung: <br />
            {insurance.unfallNumber}
          </ContactInfo>
        ) : (
          <></>
        )}
        <ContactInfo>
          Kraftfahrt- / <br />
          Haftpflichtversicherung: <br />
          {insurance.haftNumber}
        </ContactInfo>
        <ContactInfo>
          Datenschutz: <br />
          <DataPrivacyLink href={insurance.datenschutzlink} target="_blank">
            <LinkText>{insurance.datenschutztext1}</LinkText>
            <LinkText>{insurance.datenschutztext2}</LinkText>
          </DataPrivacyLink>
        </ContactInfo>
      </ContentContainer>
    </InsuranceBox>
  ));

  return (
    <Wrapper container>
      <Title>
        Haben wir Ihr <ColoredWord>Interesse</ColoredWord> geweckt?
      </Title>
      <Text>
        Die Leistungen können Sie über die teilnehmenden Versicherer erhalten.
        Die DREIklang GmbH Münster stellt nur die regionalen
        Versorgungsnetzwerke zur Verfügung. Über Weiteres informiert Sie gern
        der Versicherer.
      </Text>
      <InsurancesContainer container item>
        {renderInsuranceBoxes}
      </InsurancesContainer>
      <TextCOC>
        Sämtliche Versicherer als Vertragspartner der DREIklang GmbH
        verpflichten sich, den Verhaltenskodex des Gesamtverbandes der Deutschen
        Versicherungswirtschaft (GDV) „Code of Conduct (CoC)“ als faktischen
        Branchenstandard für Datenschutz in der Versicherungswirtschaft
        einzuhalten.
      </TextCOC>
    </Wrapper>
  );
};
