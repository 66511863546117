import React, { useState, useEffect } from "react";
import { Pagehead } from "../../components/Pagehead";
import { PageWrapper } from "../../components/Theme/style";
import constants from "./constants";
import { theme } from "../../../theme";
import { FAQ } from "../../components/faqComponent";
import { IconTextBar } from "../../components/iconTextBar";
import { Benefits1 } from "../../components/content";
import {
  FullWidthTextbox,
  Text,
  BoldText,
} from "../../components/FullWidthTextbox/style";
import { Drei } from "./style";
import { ImageContainer } from "../../components/imageContainer";
import { Wrapper } from "../networkPage/style";
import SpecialTextContainer from "../../components/specialTextComponent";
import { PartnerInsurances } from "../../components/PartnerInsurances";
import patientImg from "../../../assets/images/PatientHeaderFixCropped1.jpg";
import patientImgMobile from "../../../assets/images/PatientHeaderFixMobile1.jpg";
import exampleImg from "../../../assets/images/Krankengym2.png";
import one from "../../../assets/images/1Desktop.png";
import two from "../../../assets/images/2Desktop.png";

export const PatientsPage = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 899);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 899);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <PageWrapper container>
      <Pagehead
        isDesktop={isDesktop}
        img={isDesktop ? patientImg : patientImgMobile}
        headtext={constants.headText}
        headsubtext={isDesktop ? constants.headSubtext : ""}
        width={isDesktop ? "40%" : "60%"}
        needsDrei={true}
        imageAlt="Patientin im Krankenbett"
      />

      <Wrapper container style={{ flexWrap: "wrap-reverse" }}>
        <ImageContainer
          color={theme.color.lightpetrol}
          imgSrc={exampleImg}
          alt={"crutches"}
        />
        <SpecialTextContainer
          color={theme.color.white}
          h1title={"Was ist Dreiklang?"}
          text={
            "Das DREIklang System ist ein Zusammenspiel von Versicherungen, Krankenhäusern und Reha-Einrichtungen."
          }
          subtitle={"Die Hauptziele von DREIklang sind:"}
          subimg1={one}
          subimg2={two}
          subtext1={
            "Die Qualität der medizinischen Erst- und Weiterversorgung sowie sich anschließender Rehabilitationsmaßnahmen zu optimieren und zu koordinieren"
          }
          subtext2={
            "Die durch den Unfall entstandenen Beeinträchtigungen zu verringern, beziehungsweise im optimalen Fall zu beseitigen"
          }
          headertext={!isDesktop ? constants.headSubtext : ""}
        />
      </Wrapper>

      <FullWidthTextbox backgroundcolor={theme.color.lightgray}>
        <BoldText>Die Erreichung der Ziele&nbsp;</BoldText>
        <Text>
          ist nur möglich, wenn alle Beteiligten zusammen Hand in Hand arbeiten. Hierzu
          gehört eine reibungslose Abstimmung zwischen Krankenhaus und
          Reha-Einrichtung. Die Weiterbehandlung erfolgt nahtlos aus dem
          Krankenhaus in spezialisierte ambulante oder stationäre
          Reha-Einrichtungen - ohne Zwischenaufenthalte.
        </Text>
        <br />
        <BoldText>Dies ist eine Besonderheit von&nbsp;</BoldText>
        <Drei>DREI</Drei>
        <BoldText>klang.</BoldText>
        <br />
        <br />
        <Text>
          Darüber hinaus begleitet Sie während Ihrer Genesung ein ein medizinischer Reha-Koordinator,
          der sich mit Ihren behandelnden Ärzten und der Versicherung abstimmt.
        </Text>
      </FullWidthTextbox>

      <IconTextBar
        space
        titleFirst="Die wichtigsten"
        titleOrange="Vorteile"
        titleSecond="für Sie"
      >
        <Benefits1 />
      </IconTextBar>

      <PartnerInsurances />

      <FAQ />
    </PageWrapper>
  );
};
