import { useState, useEffect } from "react";
import { Pagehead } from "../../components/Pagehead";
import { PageWrapper } from "../../components/Theme/style";
import { Title, Wrapper } from "./style";
import constants from "./constants";
import { theme } from "../../../theme";
import {ImageContainer} from "../../components/imageContainer";
import TextComponent from "../../components/textComponent";
import { ReverseTextAndImageComponent } from "../../components/reverseTextAndImageComponent";
import { NetworkComponent } from "../../components/networkButtonComponent";
import { FullWidthTextbox, Text } from "../../components/FullWidthTextbox/style";
import doc2 from "../../../assets/images/doc2.png";
import networkImg from "../../../assets/images/NetworkHeader.png";
import networkImgMobile from "../../../assets/images/NetworkHeaderMobile.png";
import { LogoComponent } from "../../components/Theme";

export const NetworkPage = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 899);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 899);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <PageWrapper>
      <Pagehead
        isDesktop={isDesktop}
        img={isDesktop ? networkImg : networkImgMobile}
        headtext={constants.headText}
        headsubtext={isDesktop ? constants.headSubtext : ""}
        width={isDesktop ? "50%" : "60%"}
        needsDrei={true}
        imageAlt="Hände"
      />

      <Wrapper container style={{ flexWrap: "wrap-reverse" }}>
        <ImageContainer
          color={theme.color.transparentgray}
          imgSrc={doc2}
          alt={"doc2"}
        />
        <TextComponent
          color={theme.color.white}
          h2title={
            "Zu diesem Zweck baut die DREIklang GmbH regionale Netzwerke auf, " +
            "die aus Akutkliniken, stationären und ambulanten Reha-Einrichtungen bestehen. Diese Netzwerke werden den teilnehmenden " +
            "Versicherern zur Verfügung gestellt."
          }
          text={
            "Die Netzwerke ermöglichen eine optimierte Versorgung im Sinne einer geschlossenen " +
            "Behandlungskette und die Begleitung durch einen medizinischen Reha-Koordinator."
          }
          headertext={!isDesktop ? constants.headSubtext : ""}
        />
      </Wrapper>
      <FullWidthTextbox backgroundcolor={theme.color.lightpetrol}>
        <LogoComponent />
        <Title>Bereitstellung von Informationen</Title>
        <Text>
          Die Mitwirkenden im DREIklang-Netzwerk haben es sich zur Aufgabe
          gemacht, den Unfallverletzten die bestmögliche medizinische Versorgung
          zu bieten. Hierzu ist es notwendig, dass alle Beteiligten des
          DREIklang-Systems frühzeitig vom Unfall des Patienten erfahren.
        </Text>
      </FullWidthTextbox>
      <ReverseTextAndImageComponent />
      <NetworkComponent />
    </PageWrapper>
  );
};
