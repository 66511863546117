import React, { useEffect, useState } from "react";
import {
  ContentWrapper,
  H1Title,
  Subtitle,
  Image1,
  Image2,
  Div,
  HeaderTextMobile,
  Text,
  Text1,
  P
} from "./style";

interface textProps {
  color?: string;
  text: string;
  subtitle: string;
  subimg1: string;
  subimg2: string;
  subtext1: string;
  subtext2: string;
  headertext?: string;
  h1title?: string;
  isDesktop?: boolean;
}

function NewlineText(text: string) {
  return text.split("\n").map((str) => <P >{str}</P>);
}
export const SpecialTextContainer = ({
  text,
  subtitle,
  subtext1,
  subtext2,
  subimg1,
  subimg2,
  headertext,
  color,
  h1title,
}: textProps) => (
  <ContentWrapper item xs={12} sm={12} md={6} lg={6} backgroundcolor={color}>
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <HeaderTextMobile>{headertext}</HeaderTextMobile>
      <H1Title>{h1title}</H1Title>
      <Text>{NewlineText(text)}</Text>
      <Subtitle>{subtitle}</Subtitle>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            paddingTop: "5%",
          }}
        >
          <Div>
            <Image1 src={subimg1} />
          </Div>
          <Text1>{subtext1}</Text1>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            paddingTop: "5%",
          }}
        >
          <Div>
            <Image2 src={subimg2} />
          </Div>
          <Text1>{subtext2}</Text1>
        </div>
      </div>
    </div>
  </ContentWrapper>
);

export default SpecialTextContainer;
