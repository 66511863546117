import { Wrapper, Links, LinkHeader, Image, IconLink } from "../headerDesktop/style";
import { LinkImage } from "../headerMobile/style";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

let branding = require("../../../../assets/images/branding_1.png");

export const HeaderDesktop = () => {
  return (
    <Wrapper>
      <Links container direction="row">
        <LinkHeader to="/patienten">Patienten</LinkHeader>
        <LinkHeader to="/versicherer">Versicherer</LinkHeader>
        <LinkHeader to="/netzwerke">Netzwerke</LinkHeader>
        <IconLink href="https://lms.dreiklang-gmbh.info" target="_blank" rel="noopener noreferrer">
          <LockOutlinedIcon/>
        </IconLink>
      </Links>
      <LinkImage to="/">
        <Image src={branding} alt="Branding" />
      </LinkImage>
    </Wrapper>
  );
};
