import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";

export const ContentWrapper = styled(Grid)<{ backgroundcolor?: string }>`
  background-color: ${(props) => props.backgroundcolor};
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 5%;

  @media (max-width: ${theme.breakPointMobile}) {
    width: 100vw;
    height: auto;
    padding: 40px 20px 20px;
  }
`;

export const Text = styled.text`
  font: ${theme.font.text_20};
  flex-direction: column;
  width: 80%;
  height: auto;
  margin: 0;
  display: flex;
  text-align: left;
  align-items: center;
  line-height: ${theme.lineHeight.textBlock};

  @media (max-width: 1500px) {
    height: auto;
    font: ${theme.font.text_16};
    line-height: ${theme.lineHeight.textBlock};
  };

  @media (max-width: 1109px) {
    font: ${theme.font.text_14};
    line-height: ${theme.lineHeight.textBlock};
    padding-bottom: 5%;
  }

  @media (max-width: ${theme.breakPointMobile}) {
    width: 100%;
    height: auto;
    font: ${theme.font.text_16};
    line-height: ${theme.lineHeight.textBlock};
  }
`;

export const H1Title = styled.h1`
  font: ${theme.font.title_50};
  line-height: ${theme.lineHeight.H1Title_desktop};
  color: ${theme.color.petrol};
  width: 80%;
  height: auto;
  padding-right: 20px;
  padding-bottom: 5%;
  margin: 0;
  display: flex;
  text-align: left;
  align-items: center;

  @media (max-width: 1600px) {
    font-size: 40px;
    line-height: ${theme.lineHeight.H1Title_desktop};
  };
  
  @media (max-width: 1300px) {
    font: ${theme.font.h1_25};
    line-height: ${theme.lineHeight.textBlock};
  };

  @media (max-width: 1109px) {
    font-size: 25px;
    line-height: ${theme.lineHeight.H1Title_desktop};
  };

  @media (max-width: ${theme.breakPointMobile}) {
    padding-bottom: 0;
    padding-right: 0;
    width: 100%;
    font: ${theme.font.titleMobile_26};
    line-height: ${theme.lineHeight.H1Title_mobile};
  }
`;

export const H2Title = styled.h2`
  font: ${theme.font.title_30_AM};
  line-height: ${theme.lineHeight.H2Title_desktop};
  color: ${theme.color.petrol};
  width: 80%;
  height: auto;
  margin: 0 !important;
  display: flex;
  text-align: left;
  align-items: center;
  
  @media (max-width: 1200px) {
    font: ${theme.font.titleMobile_24};
    line-height: ${theme.lineHeight.H2Title_mobile};
  };
  
  @media (max-width: ${theme.breakPointMobile}) {
    width: 100%;
    font: ${theme.font.titleMobile_24};
    line-height: ${theme.lineHeight.H2Title_mobile};
  }
`;

export const P = styled.p`
  font: ${theme.font.text_20};

  width: 100%;
  height: auto;
  text-align: left;

  @media (max-width: 1300px) {
    font: ${theme.font.text_16};
    width: 100%;
  }

  @media (max-width: ${theme.breakPointMobile}) {
    width: 100%;
    height: auto;
    font: ${theme.font.text_16};
  }
`;

export const HeaderTextMobile = styled.text`
  font: ${theme.font.text_16_semibold};
  width: 80vw;

  @media (max-width: 898px) {
    padding-bottom: 20px!important;
  }





`;
