import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";

export const FullWidthTextbox = styled(Grid)<{ backgroundcolor: string }>`
  background-color: ${(props) => props.backgroundcolor};
  padding: 45px 20%;
  text-align: center;
}

  @media (max-width: ${theme.breakPointMobile}) {
    padding: 35px 20px;
  }

`;

export const Text = styled.span`
  font: ${theme.font.text_20};
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16};
  }
`;

export const BoldText = styled.span`
  font: ${theme.font.text_20_bold};
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16_bold};
  }
`;
