import {
  ContentWrapper,
  Image,
  Div,
  HeaderTextMobile,
  Text,
  Text1Titel,
  Text1Text,
  ItalicText,
} from "./style";

interface textProps {
  color?: string;
  text: string;
  img: string;
  subtext1: string;
  subtext2: string;
  italictext1: string;
  italictext2: string;
  headertext?: string;
}

function NewlineText(text: string) {
  return text.split("\n").map((str) => <p>{str}</p>);
}
export const InsurerTextContainer = ({
  text,
  subtext1,
  subtext2,
  italictext1,
  italictext2,
  headertext,
  color,
  img,
}: textProps) => (
  <ContentWrapper item xs={12} sm={12} md={6} lg={6} backgroundcolor={color}>
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: "0%",
        paddingTop: "0%",
      }}
    >
      <HeaderTextMobile>{headertext}</HeaderTextMobile>
      <Text>{NewlineText(text)}</Text>
      <div
        style={{ display: "flex", flexDirection: "column", paddingLeft: "10%" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            textAlign: "center",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row", textAlign: "left"}}
          >
            <Div>
              <Image src={img} />
            </Div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div>
                <div>
                  <Text1Titel>Resultat: </Text1Titel>
                  <Text1Text>{subtext1}</Text1Text>
                </div>
              </div>
              <ItalicText>{italictext1}</ItalicText>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            textAlign: "center",
            paddingTop: "5%",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row", textAlign: "left" }}
          >
            <Div>
              <Image src={img} />
            </Div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div>
                <div>
                  <Text1Titel>Ziel: </Text1Titel>
                  <Text1Text>{subtext2}</Text1Text>
                </div>
              </div>
              <ItalicText>{italictext2}</ItalicText>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ContentWrapper>
);

export default InsurerTextContainer;
