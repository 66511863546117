import { Wrapper, Title, Info, Paragraph } from "./style";
import { Circle } from "./circle";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";
import Patient from "../../../assets/images/patientenFixcropped.jpg";
import Versicherer from "../../../assets/images/versicherer.png";
import Netzwerke from "../../../assets/images/netzwerke.png";
import Logo from "../../../assets/images/DREIklangLogo_klein.png";

export const CircleComponent = () => {
  return (
    <Wrapper container justifyContent="center">
      <Grid item xs={12}>
        <img src={Logo} alt="Logo" />
      </Grid>
      <Title item xs={12}>
        <div>
          Das macht <span className="petrol">DREI</span>
          klang
        </div>
      </Title>
      <Info item xs={12} md={7}>
        <Paragraph>
          Die Nutzung der regionalen Netzwerke mit optimierter
          Versorgungsleistung stellen wir den teilnehmenden Unfall- und
          Haftpflichtversicherungen zur Verfügung.
        </Paragraph>
        <Paragraph>
          Die Leistungen ergänzen die Kostenübernahme der regulären
          Kostenträger, wie zum Beispiel der Krankenkasse, bedarfsgerecht.
        </Paragraph>
      </Info>
      <Grid
        container
        item
        xs={6}
        sm={12}
        justifyContent="center"
        direction="row"
      >
        <Circle
          title="Patienten"
          img={Patient}
          buttonLink="/patienten"
          color={theme.color.petrol}
        />
        <Circle
          title="Versicherer"
          img={Versicherer}
          buttonLink="/versicherer"
          color={theme.color.orange}
        />
        <Circle
          title="Netzwerke"
          img={Netzwerke}
          buttonLink="/netzwerke"
          color={theme.color.gray}
        />
      </Grid>
    </Wrapper>
  );
};
