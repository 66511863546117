import { BurgerIcon, IconLines } from "./style";

interface Props {
  onClick: () => void;
}

export const MenuBurger = ({ onClick }: Props) => (
  <BurgerIcon onClick={onClick}>
    <IconLines></IconLines>
    <IconLines></IconLines>
    <IconLines></IconLines>
  </BurgerIcon>
);
