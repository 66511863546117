import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { theme } from "../../../theme";

export const ContentWrapper = styled(Grid) <{ backgroundcolor?: string }>`
  background-color: ${(props) => (props.backgroundcolor)};
  
  @media (max-width: ${theme.breakPointMobile}) {
  width: 80vw;
  height: auto;
  }
`;

export const Image = styled.img`
  padding: 10%;
  width: -webkit-fill-available;
  width: -moz-available ;
  height: auto;
  
  @media (max-width: ${theme.breakPointMobile}) {
  width: 80vw;
  height: auto;
  }
`;

export const ImageMobile = styled.img`
width: 100vw;
height: auto;
`;
