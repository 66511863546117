import { useEffect, useState } from "react";
import { Pagehead } from "../../components/Pagehead";
import { PageWrapper } from "../../components/Theme/style";
import constants from "./constants";
import { theme } from "../../../theme";
import { IconTextBar } from "../../components/iconTextBar";
import { Versicherer1 } from "../../components/content";
import {
  FullWidthTextbox,
  Text,
  BoldText,
} from "../../components/FullWidthTextbox/style";
import { MailLink, Title, ContactText, ColoredWord } from "./style";

import insurerImg from "../../../assets/images/InsurerHeader.png";
import insurerImgMobile from "../../../assets/images/InsurerHeaderMobile.png";
import {
  ImageContainer,
  ImageContainerMobile,
} from "../../components/imageContainer";
import boredDoc from "../../../assets/images/boredDoc.png";
import boredDocMobile from "../../../assets/images/boredDocMobile.png";
import vector from "../../../assets/images/arrow_desktop.png";
import { Wrapper } from "../networkPage/style";
import InsurerTextContainer from "../../components/insurerTextComponent";

export const InsurerPage = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 959);
  const [isDesktopMobile, setDesktopMobile] = useState(window.innerWidth < 959);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 959);
    setDesktopMobile(window.innerWidth < 959);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <PageWrapper>
      <Pagehead
        isDesktop={isDesktop}
        img={isDesktop ? insurerImg : insurerImgMobile}
        headtext={constants.headText}
        headsubtext={isDesktop ? constants.headSubtext : ""}
        width={isDesktop ? "45%" : "60%"}
        needsDrei={true}
        imageAlt="Familie vor dem Laptop"
      />

      <Wrapper container style={{ flexWrap: "wrap-reverse" }}>
        {isDesktop ? (
          <ImageContainer
            color={theme.color.lightgray}
            imgSrc={boredDoc}
            alt={"doc_listens"}
          />
        ) : (
          <ImageContainerMobile imgSrc={boredDocMobile} alt={"doc_listens"} />
        )}

        {isDesktopMobile ? (
          <FullWidthTextbox
            backgroundcolor={
              isDesktop ? theme.color.lightpetrol : theme.color.lightgray
            }
          >
            <Text>Ein&nbsp;</Text>
            <BoldText>ärztlicher Reha-Koordinator</BoldText>
            <Text>
              &nbsp;begleitet den gesamten Heilverlauf. Dieser hält sich während
              des Heilverfahrens bei allen Beteiligten (Krankenhäuser,
              Reha-Einrichtungen, Versicherer sowie Patienten) informiert.
            </Text>
          </FullWidthTextbox>
        ) : (
          <></>
        )}
        <InsurerTextContainer
          color={theme.color.white}
          text={
            "Dadurch kann es passieren, dass noch nicht rehabilitationsfähige Patienten im Übergang von der Akut- in die Rehabehandlung keine durchgehende Versorgung erhalten."
          }
          img={vector}
          subtext1={
            "Die Qualität der medizinischen Erst- und Weiterversorgung sowie sich anschließender Rehabilitationsmaßnahmen ist zu optimieren und zu koordinieren."
          }
          subtext2={
            "Die durch den Unfall entstandenen Beeinträchtigungen zu verringern, beziehungsweise im optimalen Fall zu beseitigen."
          }
          italictext1={
            "In Zusammenarbeit mit den zur Verfügung gestellten regionalen Netzwerken können Sie den schwerverletzen Patienten eine durchgehende bedarfsorientierte Heilverfahrenssteuerung bieten."
          }
          italictext2={
            "Durch eine Vor-Reha-Phase - insbesondere bei gesetzlich krankenversicherten Personen - wird so früh wie möglich mit geeigneten Therapien begonnen, um ein bestmögliches Behandlungsergebnis zu erzielen. Dazu wird der Patient ohne Zwischenaufenthalte in eine spezialisierte ambulante oder stationäre Rehabilitationseinrichtung verlegt. Sofern möglich, wird bereits mit individuellen abgestimmten Therapiemaßnahmen begonnen."
          }
          headertext={!isDesktop ? constants.headSubtext : ""}
        />
      </Wrapper>

      {isDesktop ? (
        <FullWidthTextbox
          backgroundcolor={
            isDesktop ? theme.color.lightpetrol : theme.color.lightgray
          }
        >
          <Text>Ein&nbsp;</Text>
          <BoldText>ärztlicher Reha-Koordinator</BoldText>
          <Text>
            &nbsp;begleitet den gesamten Heilverlauf. Dieser hält sich während
            des Heilverfahrens bei allen Beteiligten (Krankenhäuser,
            Reha-Einrichtungen, Versicherer sowie Patienten) informiert.
          </Text>
        </FullWidthTextbox>
      ) : (
        <></>
      )}

      <IconTextBar
        titleFirst="Die"
        titleOrange="DREI"
        titleSecond="klang-Highlights auf einen Blick "
      >
        <Versicherer1 />
      </IconTextBar>

      <FullWidthTextbox
        backgroundcolor={
          isDesktop ? theme.color.lightgray : theme.color.lightpetrol
        }
      >
        <Title>
          Haben wir Ihr <ColoredWord>Interesse</ColoredWord> geweckt?
        </Title>
        <br />
        {!isDesktop && <br />}
        <ContactText>Dann melden Sie sich gern bei uns unter&nbsp;</ContactText>
        <MailLink href="mailto:service@dreiklang-gmbh.info">
          service@dreiklang-gmbh.info
        </MailLink>
      </FullWidthTextbox>
    </PageWrapper>
  );
};
