import {
  Wrapper,
  DesktopView,
  MobileView,
  Container,
  Box,
  Text,
} from "./style";
import AddIcon from "@mui/icons-material/Add";
import { theme } from "../../../theme";

export const Benefits1 = () => (
  <Wrapper
    container
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ color: theme.color.petrol }}
  >
    <DesktopView>
      <Container container direction="row">
        <Box container item xs={4}>
          <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
          <Text item xs={10}>
            <b>Medizinisch indizierte Zusatzleistungen</b> über den
            Leistungskatalog des regulären Kostenträgers hinaus (zum Beispiel
            Krankenkasse, Beihilfe, Rentenversicherung)
          </Text>
        </Box>
        <Box container item xs={4}>
          <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
          <Text item xs={10}>
            <b>Vermittlung</b> geeigneter Reha-Einrichtungen und{" "}
            <b>bedarfsgerechter Therapiemaßnahmen:</b> Ohne Verzögerung vom
            Krankenhaus direkt in die Reha-Einrichtung
          </Text>
        </Box>
        <Box container item xs={4}>
          <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
          <Text item xs={10}>
            <b>Unterstützung durch einen medizinischen Reha-Koordinator</b> als
            kontinuierlicher Ansprechpartner während Ihrer Genesung – parallel
            zu Ihren Aufenthalten im Krankenhaus und der Reha-Einrichtung{" "}
          </Text>
        </Box>
      </Container>
      <Container container direction="row">
        <Box container item xs={4}>
          <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
          <Text item xs={10}>
            <b>Chefarztbehandlung</b> im Krankenhaus
          </Text>
        </Box>
        <Box container item xs={4}>
          <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
          <Text item xs={10}>
            Unterbringung in einem <b>Ein- oder Zwei-Bett-Zimmer</b>
          </Text>
        </Box>
        <Box container item xs={4}>
          <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
          <Text item xs={10}>
            <b>Schnellere Arzttermine</b> bei <b>Spezialisten</b> des DREIklang
            Netzwerks
          </Text>
        </Box>
      </Container>
    </DesktopView>
    <MobileView>
      <Box container item xs={12}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
          <b>Medizinisch indizierte Zusatzleistungen</b> über den
          Leistungskatalog des regulären Kostenträgers hinaus (zum Beispiel
          Krankenkasse, Beihilfe, Rentenversicherung)
        </Text>
      </Box>
      <Box container item xs={12}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
          <b>Chefarztbehandlung</b> im Krankenhaus
        </Text>
      </Box>
      <Box container item xs={12}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
          <b>Vermittlung</b> geeigneter Reha-Einrichtungen und{" "}
          <b>bedarfsgerechter Therapiemaßnahmen:</b> Ohne Verzögerung vom
          Krankenhaus direkt in die Reha-Einrichtung
        </Text>
      </Box>
      <Box container item xs={12}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
          Unterbringung in einem <b>Ein- oder Zwei-Bett-Zimmer</b> sofern nicht
          medizinisch notwendig{" "}
        </Text>
      </Box>
      <Box container item xs={12}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
          <b>Unterstützung durch einen medizinischen Reha-Koordinator</b> als kontinuierlicher
          Ansprechpartner während Ihrer Genesung – parallel zu Ihren
          Aufenthalten im Krankenhaus und der Reha-Einrichtung{" "}
        </Text>
      </Box>
      <Box container item xs={12}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
          <b>Schnellere Arzttermine</b> bei <b>Spezialisten</b> des DREIklang
          Netzwerks
        </Text>
      </Box>
    </MobileView>
  </Wrapper>
);

export const Versicherer1 = () => (
  <Wrapper
    container
    alignItems="flex-start"
    justifyContent="center"
    style={{ color: theme.color.petrol }}
  >
    <Container container direction="column" item xs={12} md={4}>
      <Box container style={{ marginBottom: "26px" }}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
          <b>Die Zielsetzung:</b> Schwerverletzten Unfallopfern den Weg zurück ins Leben so leicht wie möglich machen.
        </Text>
      </Box>
      <Box container style={{ marginBottom: "26px" }}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
          Eine durchgehende standardisierte Heilverfahrenssteuerung wird
          etabliert.
        </Text>
      </Box>
    </Container>
    <Container container direction="column" item xs={12} md={4}>
      <Box container style={{ marginBottom: "26px" }}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
          Einführung einer Vor-Reha-Phase
        </Text>
      </Box>
      <Box container style={{ marginBottom: "26px" }}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
          Frühestmögliche Identifizierung schwerverletzter Patienten
        </Text>
      </Box>
      <Box container style={{ marginBottom: "26px" }}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
          Eine Win-win-Situation für alle Beteiligten
        </Text>
      </Box>
    </Container>
    <Container container direction="column" item xs={12} md={4}>
      <Box container style={{ marginBottom: "26px" }}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
        medizinischer Reha-Koordinator – ein ärztlicher Ansprechpartner für den gesamten DREIklang-Prozess
        </Text>
      </Box>
      <Box container style={{ marginBottom: "26px" }}>
        <AddIcon fontSize="medium" style={{ color: theme.color.orange }} />
        <Text item xs={10}>
          Die Reha-Lücke wird geschlossen
        </Text>
      </Box>
    </Container>
  </Wrapper>
);
