import React, { useState, useEffect } from "react";
import { Pagehead } from "../../components/Pagehead";
import { PageWrapper } from "../../components/Theme/style";
import { Wrapper } from "./style";
import { CircleComponent } from "../../components/circleComponent";
import constants from "./constants";
import { theme } from "../../../theme";
import { ImageContainer } from "../../components/imageContainer";
import bicycle from "../../../assets/images/bicycle.jpg";
import TextComponent from "../../components/textComponent";

import homepageImg from "../../../assets/images/HomepageHeader.png";
import homepageImgMobile from "../../../assets/images/HomepageHeaderMobile.png";

export const HomePage = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 899);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 899);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <PageWrapper>
      <Pagehead
        isDesktop={isDesktop}
        noBackToHome={true}
        img={isDesktop ? homepageImg : homepageImgMobile}
        headtext={constants.headText}
        headsubtext={constants.headSubtext}
        width={isDesktop ? "58%" : "75%"}
        imageAlt="Patient in der Reha"
      />
      <Wrapper container style={{ flexWrap: "wrap-reverse" }}>
        <ImageContainer
          color={theme.color.transparentgray}
          imgSrc={bicycle}
          alt={"bicycle"}
        />
        <TextComponent
          color={"white"}
          h1title={
            "DREIklang - Ein Konzept, um Verletzte \n" +
            "nach einem Unfall schnell, optimal und bedarfsgerecht zu versorgen"
          }
          text={
            "Damit die Genesung von schwerverletzten Menschen so schnell und gut wie möglich abläuft, werden über die DREIklang GmbH Münster regionale Versorgungsnetzwerke ins Leben gerufen. Hierdurch werden Schnittstellenprobleme zwischen Krankenhäusern, ambulanten und stationären Reha-Einrichtungen geschlossen und der Genesungsprozess durch bestmögliche therapeutische Versorgung und ärztliche Begleitung optimiert.   \n"
          }
        />
      </Wrapper>
      <CircleComponent />
    </PageWrapper>
  );
};
