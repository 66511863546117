import React, { useState, useEffect } from "react";
import { Pagehead } from "../../components/Pagehead";
import { PageWrapper } from "../../components/Theme/style";
import {
  Content,
  Title,
  Info,
  InnerWrapper,
  ImgImpressum,
  MobileHeader,
} from "./style";
import constants from "./constants";
import { Grid } from "@material-ui/core";
import Logo from "../../../assets/images/DREIklangLogo_klein.png";

import imprintImg from "../../../assets/images/ImpressumHeader.png";
import imprintImgMobile from "../../../assets/images/ImpressumHeaderMobile.png";

export const ImprintPage = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 899);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 899);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <PageWrapper>
      <Pagehead
        isDesktop={isDesktop}
        img={isDesktop ? imprintImg : imprintImgMobile}
        headtext={constants.headText}
        secondHeadtext={constants.headSubtext}
        width={isDesktop ? "85%" : "95%"}
        imageAlt="Krücken"
      />
      <Content container direction="row">
        <Grid container justifyContent="flex-start" alignItems="center">
          <MobileHeader>
            <ImgImpressum src={Logo} alt="Logo" />
            <Title>DREIklang GmbH Münster</Title>
          </MobileHeader>
        </Grid>

        <InnerWrapper container direction="row">
          <Info item lg={4} sm={6} xs={12}>
            <div>Kinderhauser Straße 145</div>
            <div> 48147 Münster</div>
            <div>Tel.: 0251-384229 10</div>
            <div>
              {" "}
              E-Mail:{" "}
              <a href="mailto:service@dreiklang-gmbh.info">
                service@dreiklang-gmbh.info
              </a>{" "}
            </div>
          </Info>
          <Grid container direction="column" item lg={4} sm={6} xs={12}>
            <Info>
              <div>
                <b>Handelsregister</b>
              </div>
              <div>Amtsgericht Münster</div>
              <div>Handelsregisternummer: HRB 20232</div>
            </Info>
          </Grid>
          <Grid container direction="column" item lg={4} sm={6} xs={12}>
            <Info>
              <div>
                <b>Geschäftsführer</b>
              </div>
              <div>Irina Vatter</div>
              <div>Peter Lemke</div>
              <div>Dr. Ingo Liebert</div>
              <div>Christoph Wolters</div>
            </Info>
            <Info>
              <div>
                <b>Verantwortlicher iSd § 55 RStV: </b>
              </div>
              <div>Peter Lemke</div>
              <div>Kinderhauser Str. 145,</div>
              <div>48147 Münster</div>
            </Info>
          </Grid>
        </InnerWrapper>
      </Content>
    </PageWrapper>
  );
};
