import { Grid } from "@material-ui/core";
import {
  CircleContainer,
  CircleFront,
  CircleBack,
  Button,
  CircleTitle,
} from "./style";

interface Props {
  title: string;
  img: string;
  color: string;
  buttonLink: string;
}

export const Circle = ({ title, img, color, buttonLink }: Props) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ width: "250px", margin: "50px" }}
    >
      <CircleTitle>{title}</CircleTitle>
      <CircleContainer container alignItems="center" justifyContent="center">
        <CircleFront src={img} alt="bild" />
        <CircleBack color={color} />
        <a href={buttonLink} rel="noreferrer">
          <Button>Mehr erfahren</Button>
        </a>
      </CircleContainer>
    </Grid>
  );
};
