import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";

export const Wrapper = styled(Grid)`
  flex-direction: row;
`;

export const Drei = styled.span`
  font: ${theme.font.text_20_bold};
  color: ${theme.color.petrol};
  line-height: ${theme.lineHeight.titelText};
  @media (max-width: 599px) {
    font: ${theme.font.text_16_bold};
  }
`;
