import {
  ContentWrapper,
  H1Title,
  H2Title,
  HeaderTextMobile,
  Text,
  P
} from "./style";

interface textProps {
  color?: string;
  text: string;
  headertext?: string;
  h1title?: string;
  h2title?: string;
}

function NewlineText(text: string) {
  return text.split("\n").map((str) => <P>{str}</P>);
}
export const TextContainer = ({
  text,
  headertext,
  color,
  h1title,
  h2title,
}: textProps) => (
  <ContentWrapper item xs={12} sm={12} md={6} lg={6} backgroundcolor={color}>
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
       
      }}
    >
      <HeaderTextMobile>{headertext}</HeaderTextMobile>
      {h1title ? <H1Title>{h1title}</H1Title> : <H2Title>{h2title}</H2Title>}
      <Text>{NewlineText(text)}</Text>
    </div>
  </ContentWrapper>
);

export default TextContainer;
