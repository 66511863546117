import { Link as LinkHref } from "react-router-dom";
import { Wrapper, Links, DesktopView, MobileView, LinkDisabled } from "./style";
import { footerProps } from "./mock";
import { theme } from "../../../theme";

export const Footer = () => {
  return (
    <Wrapper>
      <DesktopView>
        <Links
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {footerProps.map((link, index) => {
            return (
              <LinkHref
                key={index}
                style={{
                  textDecoration: "none",
                  height: "auto",
                  paddingLeft: "20px",
                  color: `${theme.color.petrol}`,
                  cursor: "pointer",
                }}
                to={link.url}
              >
                {link.title}
              </LinkHref>
            );
          })}
          <LinkDisabled
            style={{
              textDecoration: "none",
              height: "auto",
              paddingLeft: "20px",
              color: `${theme.color.petrol}`,
            }}
          >
            &#169; Copyright
          </LinkDisabled>
        </Links>
      </DesktopView>
      <MobileView>
        <Links
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          {footerProps.map((link, index) => {
            return (
              <LinkHref
                key={index}
                style={{
                  textDecoration: "none",
                  height: "auto",
                  color: `${theme.color.petrol}`,
                }}
                to={link.url}
              >
                {link.title}
              </LinkHref>
            );
          })}
          <LinkDisabled
            style={{
              textDecoration: "none",
              height: "auto",
              color: `${theme.color.petrol}`,
            }}
          >
            &#169; Copyright
          </LinkDisabled>
        </Links>
      </MobileView>
    </Wrapper>
  );
};
