import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";

export const Wrapper = styled(Grid)`
  padding: 60px 20px;
  text-align: center;
  background-color: ${theme.color.lightpetrol};

  .petrol {
    color: ${theme.color.petrol};
  }

  @media (max-width: ${theme.breakPointMobile}) {
    padding: 40px 20px 20px;
  }
`;

export const Title = styled(Grid)`
  font: ${theme.font.title_60};
  color: ${theme.color.white};
  margin-bottom: 20px !important;
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.title_30_semibold};
  }
`;

export const Info = styled(Grid)`
  font: ${theme.font.text_20};
  color: ${theme.color.black};
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16};
  }
`;

export const CircleContainer = styled(Grid)`
  width: 280px !important;
  position: relative;
  right: 20px;
  padding-right: 25px;
`;

export const CircleTitle = styled(Grid)`
  position: relative;
  right: 20px;
  font: ${theme.font.title_30_semibold};
  color: ${theme.color.petrol};
  @media (max-width: ${theme.breakPointMobile}) {
  }
`;

export const CircleBack = styled(Grid)<{ color?: string }>`
  height: 250px;
  width: 250px;
  background-color: ${(props) => props.color || "#fff"};
  border-radius: 50%;
  display: inline-block;
`;

export const CircleFront = styled.img`
  position: absolute;
  left: 30px;
  z-index: 2;
  height: 250px;
  width: 250px;
  border: 3px solid #ffffff;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
`;

export const Button = styled.button`
  position: absolute;
  left: 65px;
  font: ${theme.font.text_20};
  bottom: -10px;
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  z-index: 3;
`;

export const Paragraph = styled.div`
  margin-bottom: 26px;
  &.no-margin {
    margin-bottom: 0;
  }
`;
