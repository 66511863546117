import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";

export const ContentWrapper = styled(Grid)<{ backgroundcolor?: string }>`
  background-color: ${(props) => props.backgroundcolor};
  padding: 2.5% 5% 2.5%;

  @media (max-width: ${theme.breakPointMobile}) {
    width: 80vw;
    height: auto;
    padding: 40px 20px 20px;
  }
`;

export const Text = styled.text`
  font: ${theme.font.text_20};
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0;
  display: flex;
  text-align: left;
  align-items: center;

  @media (max-width: 1300px) {
    font: ${theme.font.text_16};
    width: 100%;
  }

  @media (max-width: ${theme.breakPointMobile}) {
    width: 100%;
    height: auto;
    font: ${theme.font.text_16};
  }
`;

export const P = styled.p`
  font: ${theme.font.text_20};

  width: 100%;
  height: auto;

  text-align: left;

  @media (max-width: 1300px) {
    font: ${theme.font.text_16};
    width: 100%;
  }

  @media (max-width: ${theme.breakPointMobile}) {
    width: 100%;
    height: auto;
    font: ${theme.font.text_16};
  }
`;

export const Text1 = styled.text`
  font: ${theme.font.text_20};
  flex-direction: column;
  width: 70%;
  height: auto;
  margin: 0;
  display: flex;
  text-align: left;
  align-items: center;

  @media (max-width: 1300px) {
    font: ${theme.font.text_16};
  }

  @media (max-width: ${theme.breakPointMobile}) {
    width: 80vw;
    padding-right: 5%;
    height: auto;
    font: ${theme.font.text_16};
  }
`;

export const Div = styled.div`
  width: 20%;

  @media (max-width: ${theme.breakPointMobile}) {
    width: 30%;
  }
`;

export const Subtitle = styled.text`
  font: ${theme.font.text_20_bold};
  text-align: left;
  align-items: flex-start;
  padding-right: 5%;
`;

export const H1Title = styled.h1`
  font: ${theme.font.title_50};
  line-height: ${theme.lineHeight.H1TitleSpecial};
  color: ${theme.color.petrol};
  width: 80%;
  height: auto;
  padding-right: 20px;
  margin: 0;
  display: flex;
  text-align: left;
  align-items: center;

  @media (max-width: 1300px) {
    font: ${theme.font.h1_35};
    line-height: ${theme.lineHeight.H1TitleSpecial};
  }

  @media (max-width: ${theme.breakPointMobile}) {
    padding-top: 5%;
    width: 80vw;
    font: ${theme.font.titleMobile_26};
    line-height: ${theme.lineHeight.H1TitleSpecial};
  }
`;

export const Image1 = styled.img`
  padding-right: 35%;

  @media (max-width: ${theme.breakPointMobile}) {
    width: 20%;
    padding-right: 11%;
  }

  @media (max-width: ${theme.breakPointMobile4}) {
    width: 35%;
    padding-right: 20%;
  }
`;

export const Image2 = styled.img`
  padding-right: 20%;

  @media (max-width: ${theme.breakPointMobile}) {
    width: 27%;
    padding-right: 5%;
  }

  @media (max-width: ${theme.breakPointMobile4}) {
    width: 46%;
    padding-right: 5%;
  }
`;

export const HeaderTextMobile = styled.text`
  font: ${theme.font.text_16_semibold};
  width: 80vw;

  @media (max-width: ${theme.breakPointMobile}) {
    width: 100%;
  }
`;
