import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { Grid, withStyles } from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import { Wrapper, Title, Paragraph, Content, useStyles } from "./style";
import { theme } from "../../../theme";

const Accordion = withStyles({
  root: {
    borderRadius: 0,
    boxShadow: "none",
    border: "1px solid #EDEDED",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const Icon = styled((props) => (
  <div {...props}>
    <div className="close">
      <AddIcon />
    </div>
    <div className="open">
      <AddIcon />
    </div>
  </div>
))`
  & > .open {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 5px;
    background-color: ${theme.color.white};
    color: ${theme.color.orange};
  }
  & > .close {
    display: none;
  }
  .Mui-expanded & > .close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 5px;
    background-color: ${theme.color.white};
    color: ${theme.color.orange};
  }
  .Mui-expanded & > .open {
    display: none;
  }
`;

export const FAQ = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Wrapper container justifyContent="center" className={classes.root}>
      <Title container justifyContent="center">
        <div>
          <span className="orange">FAQ</span> – die wichtigsten Fragen zu{" "}
          <span className="orange">DREI</span>
          klang
        </div>
      </Title>
      <Grid container justifyContent="center" item xs={12} sm={11} md={7}>
        <div className={classes.root}>
          <Accordion
            square
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <MuiAccordionSummary
              expandIcon={<Icon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Grid className={classes.title}>
                Ist der Versicherer bereits über meinen Unfall informiert
                worden?
              </Grid>
            </MuiAccordionSummary>
            <MuiAccordionDetails className={classes.detail}>
              <Content>
                <Paragraph>
                  Nur wenn Sie Ihren Unfall bereits bei Ihrem Unfallversicherer
                  gemeldet haben oder Ihr Unfallgegner bereits seine
                  Haftpflichtversicherung informiert hat. Eine Meldung durch die
                  Krankenhäuser oder Reha-Einrichtungen erfolgt nicht.
                </Paragraph>
              </Content>
            </MuiAccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <MuiAccordionSummary
              expandIcon={<Icon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Grid className={classes.title}>
                Welche Zusatzleistungen kann ich bekommen?
              </Grid>
            </MuiAccordionSummary>
            <MuiAccordionDetails className={classes.detail}>
              <Content>
                <Paragraph>
                  Über DREIklang können Sie nach einem Unfall von vielen
                  verschiedenen Zusatzleistungen profitieren. Dazu gehören neben
                  der Chefarztbehandlung im Krankenhaus auch die Unterbringungen
                  in einem Ein- oder Zweibettzimmer. Darüber hinaus wird Ihnen
                  eine geeignete ambulante oder stationäre Reha-Einrichtung
                  vermittelt, um Sie frühzeitig mit gezielten Therapien zu
                  versorgen. So können Sie von einer lückenlosen Behandlung
                  profitieren.
                </Paragraph>
                <Paragraph>
                  Wenn es aus therapeutischer Sicht sinnvoll ist, erhalten Sie
                  neben den Leistungen Ihrer Krankenkasse, der Beihilfe oder der
                  Rentenversicherung weitere medizinisch indizierte
                  Zusatzleistung über DREIklang. Dies sind beispielsweise
                  Lymphdrainagen, Krankengymnastik oder medizinische
                  Trainingstherapien.
                </Paragraph>
                <Paragraph>
                  Während Ihrer Genesung unterstützt Sie ein ärztlicher
                  Reha-Koordinator als kontinuierlicher Ansprechpartner. Sollte
                  die Vorstellung bei einem Spezialisten notwendig sein, kümmert
                  sich der medizinische Reha-Koordinator in Absprache mit dem
                  Versicherer um einen schnellen Termin bei einem Arzt aus dem
                  DREIklang-Netzwerk.
                </Paragraph>
              </Content>
            </MuiAccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <MuiAccordionSummary
              expandIcon={<Icon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Grid className={classes.title}>
                Worin bestehen die medizinischen Vorteile?
              </Grid>
            </MuiAccordionSummary>
            <MuiAccordionDetails className={classes.detail}>
              <Content>
                <Paragraph>
                  Gerade dann, wenn noch keine Rehafähigkeit nach einer
                  Unfallverletzung besteht, weil zum Beispiel noch keine
                  Vollbelastung des gebrochenen Beins zugelassen wurde, kann die
                  Zeit bis dahin effektiv genutzt werden. Durch gezielte und
                  individuell abgestimmte Therapien wird eine Verbesserung der
                  Gelenkbeweglichkeit, ebenso wie eine Muskelstärkung, erreicht,
                  sodass eine gute Grundkonstitution erhalten bzw. vorbereitet
                  werden kann. Dadurch können Sie von der eigentlichen
                  Rehamaßnahme besonders profitieren. Durch den Einsatz von
                  frühzeitigenan Ihre Bedürfnisse angepassten Therapien können
                  Sie von der späteren Rehamaßnahme besonders profitieren und
                  sind im besten Fall schneller wieder fit. Das Endergebnis ist
                  in der Regel besser als nach einer Therapiepause von mehreren Wochen,
                  in der Sie unter Umständen, die meiste Zeit im Rollstuhl
                  gesessen haben.
                </Paragraph>
                <Paragraph>
                  Sie selbst werden vielleicht nach der Rehamaßnahme
                  feststellen, dass Sie Fortschritte erzielt haben, aber ihre
                  noch bestehenden Defizite deutlich spüren. In der Regel werden
                  durch den Hausarzt oder Orthopäden dann keine Verordnungen
                  mehr für zum Beispiel Krankengymnastik ausgestellt. Durch
                  DREIklang besteht die Möglichkeit auch nach der Rehamaßnahme
                  nach ärztlicher Beurteilung durch den Reha-Koordinator die
                  bestehenden Defizite bestmöglich durch den gezielten Einsatz
                  von Therapien zu optimieren. Als Kostenträger unterstützt Sie
                  dann ihre private Unfallversicherung oder die
                  Haftpflichtversicherung Ihres Unfallgegners in Abstimmung mit
                  dem medizinischen Reha-Koordinator.
                </Paragraph>
              </Content>
            </MuiAccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <MuiAccordionSummary
              expandIcon={<Icon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Grid className={classes.title}>
                Können für mich Nachteile enstehen, wenn ich das Angebot nicht
                nutze?
              </Grid>
            </MuiAccordionSummary>
            <MuiAccordionDetails className={classes.detail}>
              <Content>
                <Paragraph>
                  Wenn Sie DREIklang nicht nutzen, dann werden Sie nach dem
                  Leistungskatalog Ihres regulären Kostenträgers versorgt (zum
                  Beispiel Krankenkasse oder Rentenversicherung). Dieser ist Ihr
                  erster Ansprechpartner in medizinischen Fragen.
                </Paragraph>
                <Paragraph>
                  Ihre Unfallversicherung prüft weiterhin Ihren Anspruch auf die
                  dort versicherten Leistungsarten.
                </Paragraph>
                <Paragraph>
                  Der zuständige (Kfz-)Haftpflichtversicherer prüft Ihren
                  Haftpflichtanspruch gemäß des Bürgerlichen Gesetzbuchs
                  (BGB).
                </Paragraph>
              </Content>
            </MuiAccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <MuiAccordionSummary
              expandIcon={<Icon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Grid className={classes.title}>
                Warum muss ich tätig werden und bekomme die Leistungen nicht
                automatisch?
              </Grid>
            </MuiAccordionSummary>
            <MuiAccordionDetails className={classes.detail}>
              <Content>
                <Paragraph>
                  Der Versicherer wird nicht automatisch über Ihren Unfall
                  informiert. Sobald Sie sich dort melden, kann mit Ihnen
                  zusammen das weitere Vorgehen besprochen werden. Erst mit
                  Ihrer Meldung kann der Versicherer prüfen, ob Ihr Unfall die
                  Voraussetzungen für DREIklang erfüllt.
                </Paragraph>
                <Paragraph>
                  Wenn Ihnen der Versicherer die oben beschriebenen Leistungen
                  von DREIklang anbietet, bleibt es Ihre Entscheidung, ob Sie an
                  DREIklang teilnehmen möchten. Ohne Ihre schriftliche
                  Zustimmung, können keine Leistungen erbracht werden.
                </Paragraph>
              </Content>
            </MuiAccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <MuiAccordionSummary
              expandIcon={<Icon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Grid className={classes.title}>
                Ich habe keine Unfallversicherung bei einem der teilnehmenden
                Versicherer / der Unfallverursacher ist nicht bei einem der
                teilnehmenden Versicherer versichert. Kann ich die Leistungen
                trotzdem bekommen?
              </Grid>
            </MuiAccordionSummary>
            <MuiAccordionDetails className={classes.detail}>
              <Content>
                <Paragraph>
                  Eine Teilnahme am DREIklang-System kann nicht erfolgen.
                  Besprechen Sie bitte mit Ihrem Versicherer /dem Versicherer
                  Ihres Unfallgegners, ob Sie andere Zusatzleistungen in
                  Anspruch nehmen können.
                </Paragraph>
              </Content>
            </MuiAccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <MuiAccordionSummary
              expandIcon={<Icon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Grid className={classes.title}>
                Ich wurde bereits aus dem Krankenhaus entlassen. Kann ich
                trotzdem die Leistungen bekommen?
              </Grid>
            </MuiAccordionSummary>
            <MuiAccordionDetails className={classes.detail}>
              <Content>
                <Paragraph>
                  Ja, auch dann können Sie von DREIklang profitieren. Für weiter
                  Informationen wenden Sie sich bitte an Ihren Versicherer / den
                  Versicherer Ihres Unfallgegners.
                </Paragraph>
              </Content>
            </MuiAccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <MuiAccordionSummary
              expandIcon={<Icon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Grid className={classes.title}>
                Nach meinem Unfall sind Komplikationen aufgetreten. Kann ich die
                Leistungen auch zu einem späteren Zeitpunkt in Anspruch nehmen?
              </Grid>
            </MuiAccordionSummary>
            <MuiAccordionDetails className={classes.detail}>
              <Content>
                <Paragraph>
                  Ja, auch dann können Sie von DREIklang profitieren. Für weiter
                  Informationen wenden Sie sich bitte an Ihren Versicherer / den
                  Versicherer Ihres Unfallgegners.
                </Paragraph>
              </Content>
            </MuiAccordionDetails>
          </Accordion>
        </div>
      </Grid>
    </Wrapper>
  );
};
