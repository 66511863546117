import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";

export const Wrapper = styled(Grid)`
`;

export const Title = styled.h2`
  font: ${theme.font.title_50};
  color: ${theme.color.white};
  line-height: ${theme.lineHeight.titelText};
  margin-top: 0;
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.title_26_semibold};
    line-height: ${theme.lineHeight.H2Title_desktop};
  }
`;
