import * as React from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { Grid, withStyles } from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { useStyles, InnerWrapper, StyledLink, AccordionContentContainer, SubTitle } from "./style";
import { munster } from "./data";

const Accordion = withStyles({
  root: {
    boxShadow: "none",

    borderRadius: 10,
    margin: 0,
    padding: 0,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export const MobileContent = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Grid container justifyContent="center">
      <div className={classes.root}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <MuiAccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Grid className={classes.title}>
              <div>
                Netzwerk
                <div>
                  <b>MÜNSTERLAND</b>
                </div>
              </div>
            </Grid>
          </MuiAccordionSummary>
          <MuiAccordionDetails className={classes.detail}>
            <AccordionContentContainer>
              <SubTitle item xs={12}>
                Rehaeinrichtungen
              </SubTitle>
              {munster.rehaInfos.map((infos: any, index: any) => (
                <InnerWrapper container key={index} direction="column">
                  <Grid item xs={12} md={4}>
                    <Grid>
                      <b>{infos.klinik}</b>
                    </Grid>
                    <Grid>{infos.adress}</Grid>
                    <Grid>{infos.plz}</Grid>
                    <Grid item zeroMinWidth style={{wordBreak: "break-all", marginBottom: "10px"}}>
                      Datenschutz:{" "}
                      <StyledLink href={infos.priva}>{infos.priva}</StyledLink>{" "}
                    </Grid>
                  </Grid>
                </InnerWrapper>
              ))}
              <SubTitle item xs={12}>
                Krankenhäuser
              </SubTitle>
              {munster.klinikInfos.map((infos: any, index: any) => (
                <InnerWrapper container key={index} direction="column">
                  <Grid item xs={12} md={4}>
                    <Grid>
                      <b>{infos.klinik}</b>
                    </Grid>
                    <Grid>{infos.adress}</Grid>
                    <Grid>{infos.plz}</Grid>
                    <Grid item zeroMinWidth style={{wordBreak: "break-all", marginBottom: "10px"}}>
                      Datenschutz:{" "}
                      <StyledLink href={infos.priva}>{infos.priva}</StyledLink>{" "}
                    </Grid>
                  </Grid>
                </InnerWrapper>
              ))}
            </AccordionContentContainer>
          </MuiAccordionDetails>
        </Accordion>
      </div>
    </Grid>
  );
};
