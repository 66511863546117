import React from "react";
import { Wrapper, Title, Container } from "./style";

interface Props {
  titleFirst?: string;
  titleSecond?: string;
  titleOrange?: string;
  space?: boolean;
  children: any;
}

export const IconTextBar = ({
  titleFirst,
  titleSecond,
  titleOrange,
  space,
  children,
}: Props) => {
  return (
    <Wrapper container justifyContent="center">
      <Title container justifyContent="flex-start">
        {space ? (
          <div>
            {titleFirst} <span className="orange">{titleOrange}</span>{" "}
            {titleSecond}
          </div>
        ) : (
          <div>
            {titleFirst} <span className="orange">{titleOrange}</span>
            {titleSecond}
          </div>
        )}
      </Title>
      <Container item xs={12}>
        {children}
      </Container>
    </Wrapper>
  );
};
