import React from "react";
import { HeaderDesktop } from "./headerDesktop/index";
import { HeaderMobile } from "./headerMobile/index";

export const HeaderMenu = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 899;

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return width < breakpoint ? <HeaderMobile /> : <HeaderDesktop />;
};
