import { useState } from "react";
import {
  Wrapper,
  Links,
  LinkHeader,
  Image,
  Ul,
  LinkList,
  HeaderContent,
  LinkImage,
  LinkListClosed,
  IconLink,
} from "./style";
import { MenuBurger } from "./menuBurger";
import { MenuCross } from "./menuCross";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

let branding = require("../../../../assets/images/branding_1.png");

export const HeaderMobile = () => {
  const [value, setValue] = useState<boolean>(false);

  return (
    <Wrapper>
      <HeaderContent>
        <LinkImage
          onClick={() => {
            setValue(false);
          }}
          to="/"
        >
          <Image src={branding} alt="Branding" />
        </LinkImage>
        {value ? (
          <MenuCross
            onClick={() => {
              setValue(false);
            }}
          />
        ) : (
          <MenuBurger
            onClick={() => {
              setValue(true);
            }}
          />
        )}
      </HeaderContent>
      {value ? (
        <LinkList>
          <Ul>
            <Links container direction="row">
              <LinkHeader
                onClick={() => {
                  setValue(false);
                }}
                to="/patienten"
              >
                Patienten
              </LinkHeader>
              <LinkHeader
                onClick={() => {
                  setValue(false);
                }}
                to="/versicherer"
              >
                Versicherer
              </LinkHeader>
              <LinkHeader
                onClick={() => {
                  setValue(false);
                }}
                to="/netzwerke"
              >
                Netzwerke
              </LinkHeader>
              <IconLink href="https://lms.dreiklang-gmbh.info" target="_blank" rel="noopener noreferrer">
                <LockOutlinedIcon/>
              </IconLink>
            </Links>
          </Ul>
        </LinkList>
      ) : (
        <LinkListClosed>
          <Ul>
            <Links container direction="row">
              <LinkHeader
                onClick={() => {
                  setValue(false);
                }}
                to="/patienten"
              >
                Patienten
              </LinkHeader>
              <LinkHeader
                onClick={() => {
                  setValue(false);
                }}
                to="/versicherer"
              >
                Versicherer
              </LinkHeader>
              <LinkHeader
                onClick={() => {
                  setValue(false);
                }}
                to="/netzwerke"
              >
                Netzwerke
              </LinkHeader>
              <IconLink href="https://lms.dreiklang-gmbh.info" target="_blank" rel="noopener noreferrer">
                <LockOutlinedIcon/>
              </IconLink>
            </Links>
          </Ul>
        </LinkListClosed>
      )}
    </Wrapper>
  );
};
