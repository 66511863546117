import styled from "styled-components";
import { keyframes } from "styled-components";
import { theme } from "../../../../../theme";

export const CrossIcon = styled.button`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30%;
  right: 8%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  padding: 0;
`;

const toggleAni1 = keyframes`
0% {transform: rotate(0deg); opacity: 1}
100% {transform: translateY(8px) rotate(45deg)}

`;

const toggleAni2 = keyframes`
0% {opacity: 1}
20% {background-color:${theme.color.lightpetrol}; opacity: 0.8}
100% { opacity: 0}

`;

const toggleAni3 = keyframes`
0% {  opacity: 1}
100% {transform: translateY(-8px) rotate(-45deg); opacity: 1}

`;

export const IconLines = styled.span`
  width: 29px;
  height: 3px;
  background-color: ${theme.color.petrol};
  border-radius: 3px;
  position: absolute;

  &:nth-of-type(1) {
    left: 0px;
    top: 6px;

    animation-name: ${toggleAni1};
    animation-timing-function: ease-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  &:nth-of-type(2) {
    left: 0px;
    top: 14px;

    animation-name: ${toggleAni2};
    animation-timing-function: ease-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  &:nth-of-type(3) {
    left: 0px;
    top: 22px;
    animation-name: ${toggleAni3};
    animation-timing-function: ease-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
`;
