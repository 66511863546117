import styled from "styled-components";
import { theme } from "../../../../../theme";
import { keyframes } from "styled-components";

export const BurgerIcon = styled.button`
display: flex;
flex-direction: column;
position: absolute;
top: 30%;
right: 8%;
cursor: pointer;
width: 30px;
height: 30px;
background-color: transparent;
border: none;
padding: 0;
`;

const burgerAni1 = keyframes`
0% {transform: translateY(8px) rotate(45deg)} 
100% {transform: rotate(0deg); opacity: 1}
`;

const burgerAni2 = keyframes`
0% {opacity: 0}
20% {background-color:${theme.color.lightpetrol}; opacity: 0.8}
100% {opacity: 1}
`;

const burgerAni3 = keyframes`
0%  {transform: translateY(-8px) rotate(-45deg); opacity: 1}
100% {opacity: 1}
`;

export const IconLines = styled.span`
  width: 26px;
  height: 3.2px;
  background-color: ${theme.color.petrol};
  border-radius: 2px;
  position: absolute;

  &:nth-of-type(1) {
    left: 0px;
    top: 6px;

    animation-name: ${burgerAni1};
    animation-timing-function: ease-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  &:nth-of-type(2) {
    left: 0px;
    top: 14px;

    animation-name: ${burgerAni2};
    animation-timing-function: ease-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  &:nth-of-type(3) {
    left: 0px;
    top: 22px;

    animation-name: ${burgerAni3};
    animation-timing-function: ease-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
`;
