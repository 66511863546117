import styled from "styled-components";
import { Grid } from "@material-ui/core";

import {theme} from "../../../theme"


export const PageWrapper = styled(Grid)``;

export const Logo = styled.img`
  padding-bottom: 10px;
  @media (max-width: ${theme.breakPointMobile4}) {
    font: ${theme.font.titleMobile_26};
    width: 35px;
  }
`;

interface DreiProps {
  font?: string;
  color?: string;
}


export const Drei = styled.span<DreiProps>`
  font: ${(props) => props.font};
  color: ${(props) => props.color};
  line-height: ${theme.lineHeight.titelText};
  @media (max-width: 1200px) {
    font-size: 42px;
  }
  @media (max-width: 599px) {
    font: ${theme.font.titleMobile_26};
  }
`;

export const FullWidthTextbox = styled(Grid)<{ backgroundcolor: string }>`
  background-color: ${(props) => props.backgroundcolor};
  padding: 45px 20%;
  text-align: center;
}

  @media (max-width: ${theme.breakPointMobile}) {
    padding: 40px 20px 20px;
  }
`;

export const Text = styled.span`
  font: ${ theme.font.text_20 };
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16}
  }
`;

export const BoldText = styled.span`
  font: ${ theme.font.text_20_bold };
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16_bold}
  }
`
