import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { theme } from "../../../theme";

interface PageheadTextWrapperProps {
  width: string;
}

interface DreiProps {
  color?: string;
}

export const PageheadWrapper = styled(Grid)`
  position: relative;
  width: 100%;
`;

export const BackToHomeLink = styled(Link)`
  text-decoration: none;
  color: ${theme.color.white};
  position: absolute;
  left: 6%;
  top: 4%;
  cursor: pointer;
  font: ${theme.font.title_18};
  @media (max-width: 599px) {
    font: ${theme.font.title_16};
  }
`;

export const PageheadTextWrapper = styled.div<PageheadTextWrapperProps>`
  color: ${theme.color.white};
  position: absolute;
  left: 6%;
  bottom: 5%;
  text-align: left;
  width: ${(props) => props.width};
`;

export const PageheadText = styled.span<DreiProps>`
  font: ${theme.font.title_60_semibold};
  line-height: ${theme.lineHeight.PageheadText_desktop};
  color: ${(props) => props.color};
  @media (max-width: 1200px) {
    font: ${theme.font.titleTablet_42_semibold};
    line-height: ${theme.lineHeight.PageheadText_desktop};
  }
  @media (max-width: 599px) {
    font: ${theme.font.title_26_semibold};
    line-height: ${theme.lineHeight.PageheadText_mobile};
  }
`;

export const PageheadSubtext = styled.p`
  font: ${theme.font.text_21_semibold};
  @media (max-width: 1200px) {
    font: ${theme.font.text_17_semibold};
    line-height: ${theme.lineHeight.PageheadText_desktop};
  }
  @media (max-width: 599px) {
    font:${theme.font.text_10_semibold};
    line-height: ${theme.lineHeight.PageheadSubtext_mobile};
  }
`;

export const HeaderImageWrapper = styled(Grid)`
  background: linear-gradient(
    to bottom,
    rgba(34, 131, 152, 0) 10%,
    rgba(34, 131, 152, 0.9)
  );
`;

export const HeaderImage = styled.img`
  width: 100%;
  height: auto;
  z-index: -1;
  position: relative;
  display: block;
`;
