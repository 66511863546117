import {
  PageheadWrapper,
  PageheadTextWrapper,
  PageheadText,
  PageheadSubtext,
  HeaderImage,
  HeaderImageWrapper,
  BackToHomeLink,
} from "./style";
import { LogoComponent } from "../Theme/index";
import { theme } from "../../../theme";

interface PageheadProps {
  isDesktop: boolean;
  img: string;
  headtext: string;
  secondHeadtext?: string;
  headsubtext?: string;
  width: string;
  needsDrei?: boolean;
  noBackToHome?: boolean;
  imageAlt: string;
}
export const Pagehead = ({
  isDesktop,
  img,
  headtext,
  secondHeadtext,
  headsubtext,
  width,
  needsDrei,
  noBackToHome,
  imageAlt
}: PageheadProps) => {
  return (
    <PageheadWrapper>
      {noBackToHome ? (
        <div />
      ) : (
        <BackToHomeLink to="/">
          {isDesktop ? "Zurück zur Startseite" : "Startseite"}
        </BackToHomeLink>
      )}
      <PageheadTextWrapper width={width}>
        <LogoComponent />
        <div>
          <PageheadText>
            {needsDrei && (
              <PageheadText color={theme.color.orange}>DREI</PageheadText>
            )}
            {headtext}
          </PageheadText>
        </div>
        <div>
          <PageheadText>{secondHeadtext}</PageheadText>
        </div>
        <PageheadSubtext>{headsubtext}</PageheadSubtext>
      </PageheadTextWrapper>
      <HeaderImageWrapper>
        <HeaderImage src={img} alt={imageAlt}/>
      </HeaderImageWrapper>
    </PageheadWrapper>
  );
};
