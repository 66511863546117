import styled from 'styled-components';
import {
  Grid,
} from '@material-ui/core';
import { theme } from '../../../theme';

export const Wrapper = styled(Grid)`
 padding: 30px;
   background-color: ${theme.color.white};
`;

export const Links = styled(Grid)`
 color: ${theme.color.petrol};
 font: ${theme.font.footer};
 @media screen and (max-width: ${theme.breakPointMobile}){
  font: ${theme.font.text_16};
}
`;

export const LinkDisabled = styled(Grid)`

`;

export const DesktopView = styled(Grid)`
 display: block !important;
  @media screen and (max-width: ${theme.breakPointMobile3}){
    display: none !important;
  }
`;

export const MobileView = styled(Grid)`
  display: none !important;
  @media screen and (max-width: ${theme.breakPointMobile3}){
    display: block !important;
  }
`;