import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";

export const ContentWrapper = styled(Grid)<{ backgroundcolor?: string }>`
  background-color: ${(props) => props.backgroundcolor};

  padding: 0px 20px 20px;
  
  @media (max-width: 959px) {
    width: 80vw;
    height: auto;
    padding: 40px 20px 20px;
  }

  @media (max-width: ${theme.breakPointMobile}) {
    width: 80vw;
    height: auto;
    padding: 40px 20px 20px;
  }
`;

export const Text = styled.text`
  font: ${theme.font.text_20};
  flex-direction: column;

  height: auto;
  margin: 0;
  display: flex;
  text-align: left;
  align-items: center;
  padding-left: 13%;

  @media (min-width: 1625px) {
    padding-left: 10%;
    
  }

  @media (max-width: 1300px) {
    font: ${theme.font.text_16};
  }
  
  @media (max-width: 1300px) {
    font: ${theme.font.text_14};
  }


  @media (max-width: 959px) {
    font: ${theme.font.text_16};
    width: auto;
    padding-left: 20px;
  }

  @media (max-width: ${theme.breakPointMobile}) {
    height: auto;
    font: ${theme.font.text_16};
    padding-left: 0px;
  }
`;

export const Text1Titel = styled.text`
  font: ${theme.font.text_20_bold};
  color: ${theme.color.orange};
  width: 100%;

  @media (max-width: 1300px) {
    font: ${theme.font.text_14_bold};
  }
  
  @media (max-width: 959px) {
    font: ${theme.font.text_16_bold};
   }

  @media (max-width: ${theme.breakPointMobile}) {
    width: 80vw;
    padding-right: auto;
    height: auto;
    font: ${theme.font.text_16_bold};
  }
`;

export const Text1Text = styled.text`
  font: ${theme.font.text_20};
  color: ${theme.color.petrol};
  width: 100%;
  
  @media (max-width: 1300px) {
    font: ${theme.font.text_14};
  }
  
  @media (max-width: 959px) {
    font: ${theme.font.text_16};
   }

  @media (max-width: ${theme.breakPointMobile}) {
    width: 80vw;
    padding-right: 5%;
    height: auto;
    font: ${theme.font.text_16};
  }
`;

export const ItalicText = styled.text`
  font: ${theme.font.text_20_italic};
  color: ${theme.color.petrol};
  padding-top: 5%;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0;
  display: flex;
  text-align: left;
  align-items: center;
  
  @media (max-width: 1300px) {
    font: ${theme.font.text_14_italic};
  }
  
  @media (max-width: 959px) {
    font: ${theme.font.text_16_italic};
   }

  @media (max-width: ${theme.breakPointMobile}) {
    width: 80vw;
    padding-right: 5%;
    height: auto;
    font: ${theme.font.text_16_italic};
  }
`;

export const Div = styled.div`
  margin-left: -7%;
  width: 10%;

  @media (max-width: 959px) {
    width: 7%;
  }
  @media (min-width: 1625px) {
    width: 7%;
  }
`;

export const Image = styled.img`

@media (max-width: ${theme.breakPointMobile3}) {
  width: 70%;

`;


export const HeaderTextMobile = styled.text`
  font: ${theme.font.text_16_semibold};
  padding-left: 13%;

  @media (max-width: 959px) {
    width: auto;
    padding-left: 20px;
  }

  @media (max-width: ${theme.breakPointMobile}) {
    padding-left: 0px;
  }
`;
