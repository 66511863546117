export const footerProps = [
  {
    title: 'Datenschutz',
    url: '/datenschutz',
  },
  {
    title: 'Impressum',
    url: '/impressum',
  },
];
