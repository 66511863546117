import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";

interface ImageProps {
  width: string;
  height: string;
}

export const Wrapper = styled(Grid)`
  padding: 60px 20px;
  text-align: center;
  flex-direction: column;
  background-color: ${theme.color.petrol};
  color: white;
  @media (max-width: ${theme.breakPointMobile}) {
    padding: 40px 20px;
  }
`;

export const Title = styled.div`
  font: ${theme.font.title_30_semibold};
  line-height: ${theme.lineHeight.H2Title_mobile};
  padding-bottom: 15px;
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.title_26_semibold};
    line-height: ${theme.lineHeight.titelText};
  }
`;

export const ColoredWord = styled.span`
  color: ${theme.color.orange};
`;

export const Text = styled.div`
  font: ${theme.font.text_20};
  padding-bottom: 30px;
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16};
    line-height: ${theme.lineHeight.smallTextBlock};
    padding-bottom: 20px;
  }
`;

export const TextCOC = styled.div`
  font: ${theme.font.text_20};
  padding-top: 30px;
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16};
    line-height: ${theme.lineHeight.smallTextBlock};
    padding-bottom: 20px;
  }
`;

export const InsurancesContainer = styled(Grid)`
  justify-content: center;
  flex-direction: row;
  @media (max-width: ${theme.breakPointMobile4}) {
    flex-direction: column;
  }
`;

export const InsuranceBox = styled(Grid)`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: white;
  text-align: left;
  margin: 20px;
  padding: 30px;
  @media (max-width: ${theme.breakPointMobile4}) {
    width: 100%;
    margin: 20px 0;
  } ;
`;

export const ContentContainer = styled.div`
  min-width: 197px;
`;

export const ImageContainer = styled.div`
  height: 52px;
`;

export const StyledImage = styled.img<ImageProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export const ContactInfo = styled.p`
  font: ${theme.font.text_20};
  color: black;
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16};
  }
`;

export const DataPrivacyLink = styled.a`
  width: 220px;
  display: inline-block;
  overflow-wrap: break-word;
  font: ${theme.font.text_20};
  color: black;
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16};
  }
`;

export const LinkText = styled.span`
  display: inline-block;
  text-decoration: underline;
  text-underline-offset: 4px;
  max-width: 100%;
`;
