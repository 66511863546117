import React, { useState } from "react";
import {
  Wrapper,
  InfoWrapper,
  Paragraph,
  InfoTitle,
  InnerWrapper,
  Title,
  Info,
  Button,
  ButtonContainer,
  DesktopView,
  MobileView,
  StyledLink,
  SubTitle,
} from "./style";
import { Grid } from "@material-ui/core";
import { MobileContent } from "./mobileContent";
import { networks } from "./data";

export const NetworkComponent = () => {
  const [openedNetwork, setOpenedNetwork] = useState<any | null>();

  const renderButtons = () => {
    return (
      networks.map((network, index) =>
        <Button
          className="btn"
          key={index}
          onClick={() => setOpenedNetwork(network)}
        >
          <div>
            Netzwerk
            <div>
              <b>{network.title}</b>
            </div>
          </div>
        </Button>
      )
    )
  }

  const renderClinics = () => {
    if (!openedNetwork) {
      return <></>;
    }
    return (
      <InfoWrapper
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="row"
      >
        <InfoTitle item xs={12}>
          {openedNetwork.title}
        </InfoTitle>
        <SubTitle item xs={12}>
          Rehaeinrichtungen
        </SubTitle>
        <Grid container direction="row">
        {openedNetwork.rehaInfos.map((infos: any, index: any) => (
          <InnerWrapper container direction="column" key={index} wrap="nowrap">
            <Grid>
              <b>{infos.klinik}</b>
            </Grid>
            <Grid>{infos.adress}</Grid>
            <Grid style={{ paddingBottom: "10px" }}>{infos.plz}</Grid>
            <Grid item zeroMinWidth style={{wordBreak: "break-all"}}>
              Datenschutz:{" "}
              <StyledLink href={infos.priva}>{infos.priva}</StyledLink>{" "}
            </Grid>
          </InnerWrapper>
        ))}
        </Grid>
        <SubTitle item xs={12}>
          Krankenhäuser
        </SubTitle>
        <Grid container direction="row">
        {openedNetwork.klinikInfos.map((infos: any, index: any) => (
          <InnerWrapper container direction="column" key={index} wrap="nowrap">
            <Grid>
              <b>{infos.klinik}</b>
            </Grid>
            <Grid>{infos.adress}</Grid>
            <Grid style={{ paddingBottom: "10px" }}>{infos.plz}</Grid>
            <Grid item zeroMinWidth style={{wordBreak: "break-all"}}>
              Datenschutz:{" "}
              <StyledLink href={infos.priva}>{infos.priva}</StyledLink>{" "}
            </Grid>
          </InnerWrapper>
        ))}
        </Grid>
      </InfoWrapper>
    )
  };

  return (
    <Wrapper container justifyContent="center" direction="column">
      <Title item xs={12}>
        <div>
          Das <span className="petrol">DREI</span>
          klang Netzwerk
        </div>
      </Title>
      <Info item xs={12}>
        <Paragraph>
          Wählen sie ein Netzwerk aus, um mehr Informationen zu erhalten.
        </Paragraph>
      </Info>
      <DesktopView>
        <ButtonContainer container justifyContent="center">
          {renderButtons()}
        </ButtonContainer>
        {renderClinics()}
      </DesktopView>
      <MobileView>
        <MobileContent />
      </MobileView>
    </Wrapper>
  );
};

export default NetworkComponent;
