import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { keyframes } from "styled-components";

import { theme } from "../../../../theme";

export const Wrapper = styled(Grid)`
  background-color: ${theme.color.white};
  font: ${theme.font.navigationMobile};
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 1px 0.5px ${theme.color.shadow};
  border-radius: 1px;
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: ${theme.headerHeight};
`;

export const HeaderContent = styled.div`
  height: ${theme.headerHeight};
  display: flex;
  flex-direction: row;
  background-color: ${theme.color.white};
  height: ${theme.headerHeight};
`;

export const LinkImage = styled(Link)`
  height: 3em;
`;

export const Image = styled.img`
  margin: 0.2em 0 0.2em 0;
  padding: 0.3em 0.3em 0.3em 1.5em;
  height: 4em;
  object-fit: contain;
`;

export const Links = styled(Grid)`
  height: 50%;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const smothIn = keyframes`
0%  { transform: translateY(-20rem)}
100% {transform: translateY(2rem)}
`;

export const LinkList = styled.div`
  z-index: -9999;
  background-color: ${theme.color.white};

  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: ${theme.color.lightpetrol};

  animation-name: ${smothIn};
  animation-timing-function: ease-in-ease-out;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
`;

const smothOut = keyframes`
0%  { transform: translateY(2rem)}
100% {transform: translateY(-20rem)}
`;
export const LinkListClosed= styled.div`
  z-index: -9999;
  background-color: ${theme.color.white};

  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: ${theme.color.lightpetrol};

  animation-name: ${smothOut};
  animation-timing-function: ease-in-ease-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
`;

export const Ul = styled.ul`
  margin: 0;
  padding-left: 0;
`;

export const LinkHeader = styled(Link)`
  color:  ${theme.color.black};
  font-weight:  ${theme.fontWeight.semiBold};
  text-decoration: none;
  &:hover {
    color: ${theme.color.petrol};
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 0.09em;
  }
  height: 50%;
  padding: 1em 0 1em 3.1em;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${theme.color.lightpetrol};

  text-align: left;

  &:last-child {
    border-bottom-color: transparent;
    box-shadow: 1px 1px 1px 0.5px ${theme.color.shadow};
    border-radius: 1px;
  }
`;

export const IconLink = styled.a`
  color: ${theme.color.black};
  &:hover {
    color: ${theme.color.petrol};
  };
  padding: 1em 0 0.6em 3.1em;
`;
