import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { HomePage } from "./components/pages/homePage";
import { PatientsPage } from "./components/pages/patientsPage";
import { HeaderMenu } from "./components/components/header";
import { InsurerPage } from "./components/pages/insurerPage";
import { NetworkPage } from "./components/pages/networkPage";
import { Footer } from "./components/components/footer";
import { ImprintPage } from "./components/pages/imprintPage";
import { DataPrivacyPage } from "./components/pages/dataPrivacyPage";
import { ScrollToTop } from "./components/components/scrollToTopComponent";
import { theme } from "./theme";

function App() {
  return (
    <Router>
      <div className="App">
        <HeaderMenu />
        <ScrollToTop />

        <div className="content" style={{ paddingTop: theme.headerHeight }}>
          <Routes>
            <Route path="/" element={HomePage()} />
            <Route path="/patienten" element={PatientsPage()} />
            <Route path="/versicherer" element={InsurerPage()} />
            <Route path="/netzwerke" element={NetworkPage()} />
            <Route path="/datenschutz" element={DataPrivacyPage()} />
            <Route path="/impressum" element={ImprintPage()} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
