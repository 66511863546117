import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";

export const Wrapper = styled(Grid)`
  padding: 40px 120px;
  background-color: ${theme.color.white};
 
  .orange {
    color: ${theme.color.orange};
  }

  @media (max-width: ${theme.breakPointMobile}) {
    padding: 40px 20px 20px;
  }
`;

export const Title = styled(Grid)`
  text-align: left;
  font: ${theme.font.title_30_semibold};
  color: ${theme.color.petrol};
  margin-bottom: 20px !important;
  margin-left: 34px !important;

  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.title_26_semibold};
    margin-bottom: 5px !important;
    margin-left: 0 !important;
  }
`;

export const Container = styled(Grid)`
  font: ${theme.font.text_20} !important;
  color: ${theme.color.black};
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16} !important;
  }
`;
