import styled from "styled-components";
import { theme } from "../../../theme";
import { Grid } from "@material-ui/core";

export const Wrapper = styled(Grid)``;

export const PageheadText = styled.h1`
  font-size: ${theme.font.title_60};
  width: 50%;
`;

export const PageheadSubtext = styled.p`
  font-size: ${theme.font.title_16};
  width: 50%;
`;
