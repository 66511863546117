import styled from "styled-components";
import { theme } from "../../../theme";

export const Button = styled.button`
   position: fixed; 
   text-align: center;
   border: none;
   padding: 2px;
   right: 2%;
   bottom: 8%;
   height: 50px;
   width: 50px;
   z-index: 4;
   cursor: pointer;
   border-radius: 50%;
   background-color: ${theme.color.petrol};
   color:  ${theme.color.white};
   animation: fadeIn 1.5s linear forwards;

    @keyframes fadeIn {
     0% { opacity: 0; }
     100% { opacity: 0.7; } 
}
`
export const Icon = styled.div`
   opacity: 1;
`