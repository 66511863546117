import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme";

export const Wrapper = styled(Grid)`
  padding: 40px 0 60px;
  background-color: ${theme.color.lightgray};

  .orange {
    color: ${theme.color.orange};
  }

  @media (max-width: ${theme.breakPointMobile}) {
    padding: 40px 20px 20px;
  }
`;

export const Title = styled(Grid)`
  font: ${theme.font.title_30_semibold};
  color: ${theme.color.petrol};
  margin-bottom: 50px;
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_20_bold};
  }
`;

export const Paragraph = styled.div`
  margin-bottom: 20px;
  &.no-margin {
    margin-bottom: 0;
  }
`;

export const Content = styled(Grid)``;

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      "& ..MuiAccordionSummary-root": {},
      "& .MuiAccordionSummary-content.Mui-expanded": {},
      "& .MuiAccordionSummary-root.Mui-expanded": {},
    },
    title: {
      paddingTop: 10,
      paddingBottom: 10,
      color: theme.color.petrol,
      font: theme.font.text_20,
      ["@media (max-width:768px)"]: {
        // eslint-disable-line no-useless-computed-key
        font: theme.font.text_16,
        paddingTop: 5,
        paddingBottom: 5,
      },
    },
    detail: {
      paddingRight: 60,
      //font: theme.fontDesktop.SombraRegular_26,
      ["@media (max-width:768px)"]: {
        // eslint-disable-line no-useless-computed-key
      },
    },
  })
);
