export const theme = {
  color: {
    white: "#ffffff",
    petrol: "#228398",
    lightpetrol: "#BDDAE0",
    orange: "#F3AF52",
    gray: "#C4C4C4",
    transparentgray: "rgba(196,196,196,0.3)",
    lightgray: "#EDEDED",
    black: "#000000",
    shadow: "#f5f5f5",
    transparent: "transparent",
    red: "red"
  },
  font: {
    title_60: "60px Athiti-Medium",
    title_50: "50px Athiti-Medium",
    title_26: "26px Athiti-Medium",
    title_18: "18px Athiti-Medium",
    title_16: "16px Athiti-Medium",
    title_30_AM: "30px Athiti-Medium",
    title_60_semibold: "60px Athiti-SemiBold",
    titleTablet_42_semibold: "42px Athiti-Semibold",
    title_30_semibold: "30px Athiti-SemiBold",
    title_26_semibold: "26px Athiti-SemiBold",
    title_20_semiBold: "20px Athiti-SemiBold",
    titleTablet_42: "42px Athiti-Medium",
    titleMobile_26: "26px Athiti-Medium",
    titleMobile_24: "24px Athiti-Medium",
    h1_35: "35px Athiti-Medium",
    h1_25: "25px Athiti-Medium",
    text_20: "20px OpenSans-Regular",
    text_20_italic: "20px OpenSans-Italic",
    text_20_bold: "20px OpenSans-Bold",
    text_21_semibold: "21px OpenSans-SemiBold",
    text_17_semibold: "17px OpenSans-SemiBold",
    text_10_semibold: "10px OpenSans-SemiBold",
    text_16: "16px OpenSans-Regular",
    text_16_italic: "16px OpenSans-Italic",
    text_16_semibold: "16px OpenSans-SemiBold",
    text_16_bold: "16px OpenSans-Bold",
    text_14: "14px OpenSans-Regular",
    text_14_italic: "14px Opensans-Italic",
    text_14_bold: "14px Opensans-Bold",
    navigation: "18px Athiti-SemiBold",
    navigationMobile: "16px Athiti-SemiBold",
    footer: "18px OpenSans-Regular",
  },

  fontWeight: {
    normal: "400",
    semiBold: "600",
    bold: "900",
  },

  lineHeight: {
    PageheadText_mobile: "1.12",
    PageheadSubtext_mobile: "1.12",
    PageheadText_desktop: "1.09",
    PageheadSubtext_desktop: "1.369",
    H1Title_desktop: "1.12",
    H1Title_mobile: "1.37",
    H1TitleSpecial: "1.2",
    H2Title_desktop: "1.12",
    H2Title_mobile: "1.3",
    textBlock: "1.37",
    titelText: "1",
    smallTextBlock: "1.42"
  },

  headerHeight: "5em",

  breakPointFontMax: "1920px",
  breakPointMobile: "899px",
  breakPointMobile2: "1012px",
  breakPointMobile3: "720px",
  breakPointMobile4: "599px",
};
