import { CrossIcon, IconLines } from "./style";

interface Props {
  onClick: () => void;
}

export const MenuCross = ({onClick}: Props) => (
  <CrossIcon
  onClick={onClick}>
    <IconLines></IconLines>
    <IconLines></IconLines>
    <IconLines></IconLines>
  </CrossIcon>
);
