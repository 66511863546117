import styled from "styled-components";
import { theme } from "../../../theme";

export const TextWrapper = styled.div`
  box-sizing: border-box;
  padding: 10px 50px;
  width: 100%;
`;

export const DataH1 = styled.h1`
  color: ${theme.color.petrol};
`;

export const DataH3 = styled.h3`
  color: ${theme.color.petrol};
`;

export const TextP = styled.p`
  color: ${theme.color.black};
`;

export const StrongP = styled.p`
  margin-bottom: 0;
`;

export const Liste = styled.ul`
  margin-top: 0;
`;

export const TextPBlock = styled.p`
  margin: 20px 20px;
`;

export const TextPContact = styled.p`
  margin: 0;
`;

export const TextPContactName = styled.p`
margin-bottom: 5px;
font-weight: bold;`;

export const TextPContactTelMail = styled.p`
margin-top: 5px;
margin-bottom: 0px;
`;
