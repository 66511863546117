import { theme } from "../../../theme";
import {ImageContainer} from "../imageContainer";
import TextComponent from "../textComponent";
import { Wrapper } from "./style";
import doc1and2 from "../../../assets/images/doc1and2.png";

export const ReverseTextAndImageComponent = () => {
    return (
        <Wrapper container style={{flexWrap: "wrap-reverse"}}>
            <TextComponent
                color={theme.color.white}
                text={'Die Patienten erfahren bereits in der Akutklinik von dem bestehenden DREIklang- ' +
                    'Netzwerk, da Ihnen dort umfassendes Informationsmaterial bereitgestellt wird. Mit ' +
                    'der Unfallmeldung bei dem Versicherer können die Patienten dann klären, ob die ' +
                    'Zusatzleistungen über DREIklang für sie in Frage kommen.' +
                    '\n' +
                    'Die DREIklang GmbH ist selbst nicht an der Steuerung der Schadenfälle beteiligt.'}/>
            <ImageContainer
                color={theme.color.transparentgray}
                imgSrc={doc1and2}
                alt={'doc1and2'}/>
        </Wrapper>
    )
}