import styled from 'styled-components';
import {
  Grid,
} from '@material-ui/core';
import { theme } from '../../../theme';

export const Wrapper = styled(Grid)`
 text-align: left;

   .petrol{
    color: ${theme.color.petrol};
   }

   @media (max-width: ${theme.breakPointMobile}){
   
   }
`;

export const Container = styled(Grid)`
margin-bottom: 26px;


@media (max-width: ${theme.breakPointMobile}){
  margin-bottom: 0;
 
  }
`;

export const Box = styled(Grid)`
  @media (max-width: ${theme.breakPointMobile}){
    margin-top: 10px!important;
    margin-bottom: 10px!important;
    width: 100% !important;
    
  }
`;

export const Paragraph = styled.div`
  margin-bottom: 26px;
    &.no-margin {
    margin-bottom: 0;
}
`;

export const Text = styled(Grid)`
  margin-left: 10px !important;
  @media screen and (max-width: ${theme.breakPointMobile}){
    max-width: fit-content;
    margin-left: 15px !important;
  }
  

`;

export const DesktopView = styled(Grid)`
 display: block !important;
  @media screen and (max-width: ${theme.breakPointMobile}){
    display: none !important;
  }
`;

export const MobileView = styled(Grid)`
  display: none !important;
  @media screen and (max-width: ${theme.breakPointMobile}){
    display: block !important;
   
  }
`;