export const munster = {
  title: "Münsterland",
  rehaInfos: [
    {
      klinik: "ZaR Zentrum für ambulante Rehabilitation GmbH:",
      adress: "Grevener Str. 182",
      plz: "48159 Münster",
      priva: "https://www.zar-ms.de/datenschutz",
    },
    {
      klinik: "Klinik Maria Frieden Telgte:",
      adress: "Am Krankenhaus 1",
      plz: "48291 Telgte",
      priva:"https://www.maria-frieden-telgte.de/kontaktdatenschutz/datenschutz.html",
    },
    {
      klinik: "Klinik am Rosengarten im Staatsbad Oeynhausen GmbH:",
      adress: "Westkorso 22",
      plz: "32545 Bad Oeynhausen",
      priva:"https://www.klinikamrosengarten.de/datenschutz/index.php",
    },
    {
      klinik: "VAMED Klinik Hattingen GmbH",
      adress: "Am Hagen 20",
      plz: "45527 Hattingen",
      priva:"https://www.vamed-gesundheit.de/datenschutz",
    },
    {
      klinik: "St.Josef-Stift gGmbH",
      adress: "Westtor 7",
      plz: "48324 Sendenhorst",
      priva:"https://www.st-josef-stift.de/datenschutz",
    },
    {
      klinik: "medicos.Osnabrück GmbH",
      adress: "Weidenstr. 2-4",
      plz: "49080 Osnabrück",
      priva:"https://www.medicos-osnabrueck.de/datenschutz-impressum/",
    },
    {
      klinik: "Ambulante Reha Bad Hamm GmbH",
      adress: "Arthur-Dewitz-Str. 5",
      plz: "59065 Hamm",
      priva:"https://www.reha-bad-hamm.de/datenschutz",
    },
  ],
  klinikInfos: [
    {
      klinik: "St. Franziskus-Hospital GmbH Münster:",
      adress: "Hohenzollernring 70",
      plz: "48145 Münster",
      priva: "https://www.sfh-muenster.de/datenschutz",
    },
    {
      klinik: "St. Marien-Hospital Lüdinghausen GmbH:",
      adress: "Neustr. 1",
      plz: "59348 Lüdinghausen",
      priva:"https://www.smh-luedinghausen.de/kontaktdatenschutz/datenschutz.html",
    },
    {
      klinik: "Maria-Josef-Hospital Greven GmbH:",
      adress: "Lindenstr. 29",
      plz: "48268 Greven",
      priva:"https://www.maria-josef-hospital.de/kontaktdatenschutz/datenschutz.html",
    },
    {
      klinik: "Herz-Jesu-Krankenhaus Hiltrup GmbH:",
      adress: "Westfalenstr. 109",
      plz: "48165 Münster-Hiltrup",
      priva:"https://hjk-muenster.de/datenschutz-1.html",
    },
    {
      klinik: "Josephs-Hospital Warendorf:",
      adress: "Am Krankenhaus 2",
      plz: "48231 Warendorf",
      priva:"https://www.jhwaf.de/datenschutz",
    },
    {
      klinik: "Raphaelsklinik Münster:",
      adress: "Loerstr. 23",
      plz: "48143 Münster",
      priva:"https://www.raphaelsklinik.de/datenschutz",
    },
    {
      klinik: "Clemenshospital Münster:",
      adress: "Düesbergweg 124",
      plz: "48153 Münster",
      priva:"https://www.clemenshospital.de/datenschutz",
    },
  ],
};

export const ruhrgebiet = {
  title: "Ruhrgebiet",
  rehaInfos: [
    {
      klinik: "VAMED Klinik Hattingen GmbH",
      adress: "Am Hagen 20",
      plz: "45527 Hattingen",
      priva: "https://www.vamed-gesundheit.de/datenschutz",
    },
    {
      klinik: "Orthomed Medizinisches Leistungs- und Rehabilitationszentrum GmbH",
      adress: "Strobelallee 58",
      plz: "44139 Dortmund",
      priva: "https://www.orthomed-rehazentrum.de/datenschutz",
    },
    {
      klinik: "Ambulante Reha Bad Hamm GmbH",
      adress: "Arthur-Dewitz-Str. 5",
      plz: "59065 Hamm",
      priva:"https://www.reha-bad-hamm.de/datenschutz",
    },
  ],
  klinikInfos: [
    {
      klinik: "Klinikum Dortmund gGmbH",
      adress: "Beuerhausstr. 40",
      plz: "44137 Dortmund",
      priva: "https://www.klinikumdo.de/datenschutz",
    },
  ],
};

export const networks = [munster, ruhrgebiet];
