import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { theme } from "../../../theme";

export const Content = styled(Grid)`
  padding: 40px 50px 20px;
  background-color: ${theme.color.lightgray};

  @media (max-width: ${theme.breakPointMobile}) {
    padding: 40px 20px 20px;
  }
`;

export const MobileHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ImgImpressum = styled.img`
  width: 30px;
  display: block;
  margin auto 0 auto 0;
  padding-right: 10px;
  @media (max-width: ${theme.breakPointMobile}) {
    width: 25px;
  }
`;

export const InnerWrapper = styled(Grid)`
  padding: 10px 50px;

  @media (max-width: ${theme.breakPointMobile4}) {
    padding: 10px 30px;
  }
`;

export const Title = styled.h2`
  font: ${theme.font.title_26_semibold};
  color: ${theme.color.petrol};
  display: block;
  margin auto 0 auto 0;
  width: 100vw;
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.title_20_semiBold};
    width: 80vw;
  }
`;
export const Info = styled(Grid)`
  font: ${theme.font.text_20};
  margin-bottom: 20px !important;
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.font.text_16};
  }
`;
