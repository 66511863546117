import { ContentWrapper, Image, ImageMobile } from "./style";
interface ImageProps {
  color?: string;
  imgSrc?: string;
  alt?: string;
}

interface ImagePropsMobile {
  imgSrc?: string;
  alt?: string;
}

export const ImageContainer = ({ imgSrc, color, alt }: ImageProps) => (
  <ContentWrapper item xs={12} sm={12} md={6} lg={6} backgroundcolor={color}>
    <Image src={imgSrc} alt={alt} />
  </ContentWrapper>
);

export const ImageContainerMobile= ({ imgSrc, alt }: ImagePropsMobile) => (
  <ContentWrapper item xs={12} sm={12} md={6} lg={6}>
    <ImageMobile src={imgSrc} alt={alt} />
  </ContentWrapper>
);

